import { useEffect, useState, useTransition } from 'react';

async function readAllowedOrigins() {
  return fetch(
    '',
  ).then((response) => response.json());
}

export function useWhiteList() {
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [isFetchAllowedOriginsPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      readAllowedOrigins().then((whitelist) => setAllowedOrigins(whitelist));
    });
  }, []);

  return {
    allowedOrigins,
    isFetchAllowedOriginsPending,
  };
}
